import styled from "styled-components";
import * as yup from "yup";
import {Link, router, usePage} from "@inertiajs/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import logo from "@/assets/LogoFooter.png";
import forgotPasswordImage from "@/assets/resgisterimage.png";
import { FiX } from "react-icons/fi";
import toast from "react-hot-toast";

import { Button } from "@nextui-org/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";


type FormData = {
    email: string;
};

const forgotPasswordSchema = yup
    .object({
        email: yup.string().required().email(),
    })
    .required();

type ForgotPasswordProps = {
    closeModal: () => void;
    openLoginModal: () => void;
};

const StyledForgotPassword = styled.div`
    background-repeat: no-repeat;
    background-size: cover;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    closeModal,
    openLoginModal,
}) => {
    const { locale } = usePage().props;
    const { t } = useTranslation(["glossary", "validation"]);
    const { executeRecaptcha } = useGoogleReCaptcha()
    const loading = useSelector((state: RootState) => state.plan.loading)
    const forgotPasswordSchema = yup
        .object({
            email: yup.string().email(t("validation:error-email")).required(t("validation:required-email")),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({ resolver: yupResolver(forgotPasswordSchema) });

    const onSubmit = useCallback(
        (data: FormData) => {

            if (!executeRecaptcha) {
                return;
            }
            executeRecaptcha("form").then((gReCaptchaToken: string) => {
                submitForm(gReCaptchaToken, data);
            });
        },
        [executeRecaptcha]
    );

    const submitForm = (gReCaptchaToken: string, data: FormData) => {
        router.post(`/${locale}/password/email`, { ...data, "g-recaptcha-response": gReCaptchaToken }, {
            onSuccess: () => {
                closeModal();
                openLoginModal();
                toast.success(
                    t("forgot_password_success", { ns: "validation" }),
                );
            },
        });
    }

    return (
        <StyledForgotPassword>
            <div className="h-screen flex flex-row-reverse bg-[#070F2B]">
                <div
                    className="xl:w-1/2 w-full h-full flex items-center justify-center relative"
                    style={{
                        backgroundImage: `url(${forgotPasswordImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center right",
                    }}
                >
                    <button
                        className="absolute xl:block hidden text-white top-12 right-12"
                        onClick={closeModal}
                    >
                        <FiX size={40} />
                    </button>
                    <button
                        className="absolute block xl:hidden text-white top-6 right-6"
                        onClick={closeModal}
                    >
                        <FiX size={34} />
                    </button>
                    <div className="absolute top-0 left-0 w-full h-full pointer-events-none xl:bg-gradient-to-r from-[#070F2B]"></div>
                    <div className="p-8 rounded-lg xl:max-w-xl md:max-w-2xl w-full relative z-10">
                        <div className="xl:hidden flex justify-center mb-8">
                            <img
                                src={logo}
                                alt="Thassos Greece"
                                className="h-16 mb-8"
                            />
                        </div>

                        <h1 className="text-white font-bold md:text-3xl text-2xl mb-4">
                            {t("forgot_password")}
                        </h1>
                        <p className="text-white font-extrathin text-lg mb-8">
                            {t("back_to")}{" "}
                            <span
                                className="ml-1 underline font-bold cursor-pointer"
                                onClick={() => {
                                    closeModal();
                                    openLoginModal();
                                }}
                            >
                                {t("login")}
                            </span>
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="email"
                                    {...register("email")}
                                    className="p-3 w-full bg-[#6d7079] rounded-[15px] backdrop-blur-[50px] text-white placeholder-white"
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-sm">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>
                            <div className="flex flex-col md:flex-row justify-between">
                                <Button
                                    isLoading={loading}
                                    disabled={loading}
                                    title={t("send")}
                                    type="submit"
                                    className="w-full md:w-2/5 py-7 px-4 text-white text-lg font-medium bg-[#1D24CA] rounded-[10px] hover:bg-blue-700"
                                >
                                    {t("send")}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-1/2 h-full bg-[#070F2B] hidden xl:flex items-center justify-center">
                    <img src={logo} alt="Thassos Greece" className="h-30 " />
                </div>
            </div>
        </StyledForgotPassword>
    );
};

export default ForgotPassword;
